import { render, staticRenderFns } from "./settlement.vue?vue&type=template&id=74be490a&scoped=true&"
import script from "./settlement.vue?vue&type=script&lang=js&"
export * from "./settlement.vue?vue&type=script&lang=js&"
import style0 from "./settlement.vue?vue&type=style&index=0&id=74be490a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74be490a",
  null
  
)

export default component.exports